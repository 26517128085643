export const bgColor = {
    light: 'white',
    dark: 'gray.900' 
  };
  export const primaryTextColor = {
    light: 'black',
    dark: 'white'
  };
  export const navBgColor = {
    light: 'rgba(255, 255, 255, 0.8)',
    dark: 'rgba(23, 25, 35, 0.8)'
  };
  
  export const secondaryTextColor = {
    light: 'gray.700',
    dark: 'gray.400'
  };
  
  export const borderColor = {
    light: 'gray.200',
    dark: 'gray.600'
  };
  
  export const iconColor = {
    light: 'gray.1000',
    dark: 'white'
  };