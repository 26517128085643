export const Footer = () => {
  return (
    <>
      <footer>
      </footer>
      <br />
      <br />
      <br />
    </>
  );
};
